import React, { Component } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default class BasicSlider extends Component {
  render () {
    const { items, handleClick, settings, setSliderInstance, classNames } = this.props

    return (
      <div className={`basic-slider ${classNames}`}>
        <Slider ref={slide => setSliderInstance && setSliderInstance(slide)} {...settings}>
          {items.map(item => (
            <div key={item.id} className='container' style={{ width: 350 }} onClick={handleClick}>
              <div className={`image ${item.id === 4 && 'loyalty'}`}>
                <img src={item.image} />
                {item.id === 3 && (
                  <div>
                    <p>{item.image_text_1}</p>
                    <p>{item.image_text_2}</p>
                    <p>{item.image_text_3}</p>
                  </div>)}
              </div>
              <div className='title'>{item.title}</div>
              <p>{item.description}</p>
              {item.icon_1 && (
                <div className='loyalty-slide'>
                  <div className='step1'>
                    <div className='image'>
                      {item.icon_1}
                    </div>
                    <p>{item.icon_text_1}</p>
                  </div>
                  <div className='arrow'>&gt;</div>
                  <div className='step2'>
                    <div className='image'>
                      <img src={item.icon_2} />
                    </div>
                    <p>{item.icon_text_2}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

BasicSlider.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  settings: PropTypes.object,
  setSliderInstance: PropTypes.func,
  classNames: PropTypes.string,
  handleClick: PropTypes.func
}

import React, { Component } from "react";
import propTypes from "prop-types";
import { observer, inject } from "mobx-react";
import withRouter from "../../components/withRouter";
import { Button } from "react-bootstrap";
import { Checkbox } from "../../components/formComponents";
import t from "../../service/translate.service";
import moment from "moment";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import { Alert } from "antd";
import {
  extractError,
  extractErrorFromSap,
} from "../../service/response.service";
import { Link } from "react-router-dom";

class ContractOverview extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
    consumptionStore: propTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      contract: "",
      loading: true,
      errorMsg: "",
    };
  }

  async componentDidMount() {
    try {
      const id = this.props.useParams.contractNumber;
      const selectedContract = this.props.contractStore.activeContracts.find(
        (c) => c.contractNumber === id
      );

      if (!selectedContract.rentAccountBalance) {
        await this.props.contractStore.fetchAccountBalance(selectedContract);
      }
      this.setState({ contract: selectedContract });
      this.contract = selectedContract;
      this.props.contractStore.getContractDetails(
        selectedContract.contractNumber
      );

      const overdueToApp =
        await this.props.consumptionStore.getConsumptionRegistrationInfo(
          selectedContract.contractNumber,
          "Overdue"
        );
      this.setState({ overdueToApp });
    } catch (err) {
      this.setState({
        loading: false,
        errorMsg:
          this.props.contractStore.error ||
          extractErrorFromSap(err) ||
          t[extractError(err)] ||
          t["ERROR"],
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  optIn = async () => {
    try {
      this.setState({
        loading: true,
        overdueToApp: !this.state.overdueToApp,
      });

      await this.props.consumptionStore.consumptionRegister(
        this.state.contract,
        !this.state.overdueToApp,
        "Overdue"
      );

      const response =
        await this.props.consumptionStore.getConsumptionRegistrationInfo(
          this.state.contract.contractNumber,
          "Overdue"
        );

      this.setState({ overdueToApp: response });
    } catch (err) {
      this.setState({
        loading: false,
        errorMsg:
          this.props.contractStore.error ||
          extractErrorFromSap(err) ||
          t[extractError(err)] ||
          t["ERROR"],
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  showModal = () => {
    this.modal.visible = true;
  };

  openBalanceHistory = (contract) => {
    this.props.navigate(`/rent-account/${contract.contractNumber}`);
  };

  openPrepayments = (contract) => {
    this.props.navigate(`/contracts/prepayments/${contract.contractNumber}`)
  }

  render() {
    const { loading, overdueToApp, contract, errorMsg } = this.state;

    if (loading || this.props.contractStore.loading) {
      return <LoadingPageOverlay />;
    }

    const {
      contractStartDate,
      contractEndDate,
      coldCosts,
      operationalCosts,
      heatingCosts,
      tenants,
      totalCost,
      contractDetailsError,
      operationalCostPrepaymentEnabled
    } = this.props.contractStore;

    if (contractDetailsError.length) {
      return (
        <Alert
          message={contractDetailsError}
          type="error"
          style={{ marginBottom: "12px", marginTop: "12px" }}
        />
      );
    }

    return (
      <div className="contract-list">
        {errorMsg && (
          <Alert
            message={errorMsg}
            type="error"
            style={{ marginBottom: "12px", marginTop: "12px" }}
          />
        )}

        <div style={{ margin: 30 }}>
          <table style={{ fontSize: 20 }}>
            <thead>
              <tr className="contractOverviewth">
                <th className="contractOverviewtd" style={{ width: "300px" }}>
                  {t.CONTRACT_OVERVIEW_DETAILS}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="contractOverviewtd">
                  {t.LOGIN_PAGE_TENANT_NUMBER}:
                </td>
                <td className="contractOverviewtd">
                  {this.contract.contractNumber}
                </td>
              </tr>
              <tr>
                <td className="contractOverviewtd">
                  {t.RENT_ACCOUNT_START_DATE}:
                </td>
                <td className="contractOverviewtd">
                  {moment(contractStartDate).isValid()
                    ? moment(contractStartDate).format("DD.MM.YYYY")
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="contractOverviewtd">
                  {t.RENT_ACCOUNT_END_DATE}:
                </td>
                <td className="contractOverviewtd">
                  {moment(contractEndDate).isValid()
                    ? moment(contractEndDate).format("DD.MM.YYYY")
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="contractOverviewtd">{t.USER_ADDRESS}:</td>
                <td className="contractOverviewtd">{this.contract.address}</td>
              </tr>
              <tr>
                <td className="contractOverviewtd">
                  {tenants &&
                    tenants.map((tenant, index) => (
                      <p
                        key={index}
                        className="contractOverviewtd"
                        style={{ paddingBottom: 0, marginBottom: "8px" }}
                      >
                        {tenant.firstName} {tenant.lastName}
                      </p>
                    ))}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ fontSize: 20, marginTop: 30 }}>
            <thead>
              <tr className="contractOverviewth">
                <th className="contractOverviewtd" style={{ width: "300px" }}>
                  {t.MY_RENT}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="contractOverviewtd">{t.COLD_RENT}:</td>
                <td className="contractOverviewtd">{coldCosts}</td>
              </tr>
              <tr>
                <td className="contractOverviewtd">{t.OPERATIONAL_COSTS}:</td>
                <td className="contractOverviewtd">{operationalCosts}</td>
              </tr>
              <tr>
                <td className="contractOverviewtd">{t.HEATING_COSTS}:</td>
                <td className="contractOverviewtd">{heatingCosts}</td>
              </tr>
              <tr>
                <td className="contractOverviewtd">{t.TOTAL_COST}:</td>
                <td className="contractOverviewtd">{totalCost}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: 80 }} className="loyalty-upload">
          <Button
            className="button primary"
            onClick={() => this.openBalanceHistory(contract)}
          >
            {`${t.CONTRACT_RENT_ACCOUNT_BALANCE}`}
          </Button>
          {this.props.contractStore.rentContractDocumentsEnabled && (
            <div className="text-center">
              <Button
                onClick={() =>
                  this.props.navigate(
                    `/rent-contract-documents/${this.props.useParams.contractNumber}`
                  )
                }
                className="button primary"
              >
                {t.RENT_CONTRACT_DOCUMENTS}
              </Button>
            </div>
          )}
          {this.props.contractStore.ocCalculationsEnabled && (
            <div className="text-center">
              <Button
                onClick={() =>
                  this.props.navigate(
                    `/oc-calculations/${this.props.useParams.contractNumber}`
                  )
                }
                className="button primary"
              >
                {t.VIEW_OC_CALCULATION}
              </Button>
            </div>
          )}
          {operationalCostPrepaymentEnabled &&
                <Button
                className="button primary"
                onClick={() => this.openPrepayments(this.contract)}
              >
                {t.PREPAYMENT_OVERVIEW_BUTTON}
              </Button>
          }
          <div style={{ margin: 30 }}>
            <Checkbox
              label={t.CONTARCT_RENT_DUE_OPT_IN}
              value={overdueToApp}
              onChange={this.optIn}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  "contractStore",
  "consumptionStore"
)(withRouter(observer(ContractOverview)));
